<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-100">
                <div class="w-90 center">
                    <router-link :to="{ name: 'ManageAssets' }">&lt; Back</router-link>
                </div>
                <div class="f3 b w-90 center pv4" style="padding-right: 2px">
                    <span>Bank {{ $route.query?.bank ? `(${$route.query?.bank})` : "" }}</span>
                </div>
                <div class="w-90 center mv4 adj-text">
                    <div class="flex flex-wrap">
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img :src="require('@/assets/images/revenue.png')" alt="" />
                                <div class="pl2">Transactions</div>
                            </div>
                            <div class="f5 f4-l font-w2">{{ formatQuantity(state.accounting.totalTransactions) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img :src="require('@/assets/images/revenue.png')" alt="" />
                                <div class="pl2">Total Debits</div>
                            </div>
                            <div class="f5 f4-l font-w2">
                                {{ formatAmount(Math.abs(state.accounting.totalDebits), $store.state.Settings?.currency) }}
                            </div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img :src="require('@/assets/images/revenue.png')" alt="" />
                                <div class="pl2">Total Credits</div>
                            </div>
                            <div class="f5 f4-l font-w2">
                                {{ formatAmount(state.accounting.totalCredits, $store.state.Settings?.currency) }}
                            </div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img :src="require('@/assets/images/revenue.png')" alt="" />
                                <div class="pl2">Account Balance</div>
                            </div>
                            <div class="f5 f4-l font-w2">
                                {{
                                    formatAmount(
                                        state.accounting.totalCredits - Math.abs(state.accounting.totalDebits),
                                        $store.state.Settings?.currency
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-between pb3 box-border-bottom w-90 center">
                    <div></div>
                    <div class="flex flex-wrap justify-between items-center w-50-l w-100 mt0-l mt2">
                        <select v-model="state.dateFilter" class="card-box mb3 mb0-l" id="" style="padding: 10px 5px">
                            <option value="">All</option>
                            <option value="last7Days">Last 7days</option>
                            <option value="currentMonth">This Month</option>
                            <option value="lastMonth">Last Month</option>
                            <option value="thisYear">This Year</option>
                            <option value="lastYear">Last Year</option>
                        </select>
                        <input
                            type="date"
                            v-model="state.start"
                            class="input-style card-box mb3 mb0-l"
                            placeholder="Start date"
                            style="padding: 10px 5px"
                            :disabled="state.loading"
                        />
                        <input
                            type="date"
                            v-model="state.end"
                            class="input-style card-box mb3 mb0-l"
                            placeholder="End date"
                            style="padding: 10px 5px"
                            :disabled="state.loading"
                        />
                        <button
                            class="flex items-center filter card-box entity-filter-btn"
                            style="padding: 10px 5px"
                            @click="filterResult"
                        >
                            <img :src="require('@/assets/images/filter_list.svg')" class="pl3" alt="" />
                            <span class="pl2 w-50 tl black">Filter</span>
                        </button>
                    </div>
                </div>
                <div class="scrollmenu w-90 center">
                    <table class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr3 box-border-bottom tl">Transaction Date</th>
                                <th class="pb3 pr3 box-border-bottom tl">Value Date</th>
                                <th class="pb3 pr3 box-border-bottom tl">Transaction Id</th>
                                <th class="pb3 pr3 box-border-bottom tl">Description</th>
                                <th class="pb3 pr3 box-border-bottom tl">Debit</th>
                                <th class="pb3 pr3 box-border-bottom tl">Credit</th>
                                <th class="pb3 box-border-bottom tl pr2">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="transaction of state.accounting.transactions" :key="transaction._id">
                                <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(transaction.createdAt) }}</td>
                                <td class="pv3 pr3 box-border-bottom tl">{{ formatDate(transaction.date) }}</td>
                                <td class="pv3 pr3 box-border-bottom tl bb font-w2">
                                    <router-link 
                                        class="bb font-w2"
                                        v-if="transaction?.reference && !transaction?.isDelete" 
                                        :to="{ name: 'Transaction', params: { id: transaction?.reference } }"
                                    >
                                        {{ transaction.number }}
                                    </router-link>
                                    <span v-else>{{ transaction?.number || '-' }}</span>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">{{transaction?.description || ''}}</td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    <span v-if="transaction.impact === 'debit'">{{
                                        formatAmount(transaction.glAmount, $store.state.Settings?.currency)
                                    }}</span>
                                    <span v-else>-</span>
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    <span v-if="transaction.impact === 'credit'">{{
                                        formatAmount(transaction.glAmount, $store.state.Settings?.currency)
                                    }}</span>
                                    <span v-else>-</span>
                                </td>
                                <td class="pv3 box-border-bottom tl pr2">
                                    <span>{{
                                        formatAmount(transaction.currBalance, $store.state.Settings?.currency)
                                    }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <Pagination
                    :pages="state.accounting.transactions?.pagination"
                    route-name="LiabilitiesBorrowing"
                    :onNavigate="filterResult"
                />
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { formatAmount, formatQuantity, formatDate, formatDateFilter, getLastSevenDaysDateRange } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper.vue'
import Pagination from '@/components/Pagination'
export default {
    name: 'EquityOwnersContribution',
    components: { AppWrapper, Pagination },

    setup() {
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        const day = today.getDate()

        const store = useStore()
        const route = useRoute()
        const state = reactive({
            dateFilter: 'currentMonth',
            start: formatDateFilter(new Date(year, month, 1)),
            end: formatDateFilter(new Date()),
            accounting: {},
        })
        watch(
            () => state.dateFilter,
            (value, prev) => {
                if (value === 'last7Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange()
                    state.start = formatDateFilter(startDate)
                    state.end = formatDateFilter(endDate)
                } else if (value === 'currentMonth') {
                    ;(state.start = formatDateFilter(new Date(year, month, 1))), (state.end = formatDateFilter(new Date()))
                } else if (value === 'lastMonth') {
                    ;(state.start = formatDateFilter(new Date(year, month - 1, 1))),
                        (state.end = formatDateFilter(new Date(year, month, 0)))
                } else if (value === 'thisYear') {
                    ;(state.start = formatDateFilter(new Date(year, 0, 1))), (state.end = formatDateFilter(new Date(year, 12, 0)))
                } else if (value === 'lastYear') {
                    ;(state.start = formatDateFilter(new Date(year - 1, 0, 1))),
                        (state.end = formatDateFilter(new Date(year - 1, 12, 0)))
                }

                filterResult()
            }
        )

        const filterResult = () => {
            const query = {
                bank: route.query?.bank || '',
                type: 'assets',
                subtype: 'bank',
                page: route.query.page || 1,
                limit: route.query.limit || 25,
                startDate: state.start,
                endDate: state.end,
            }
            store.dispatch('Accounting/getChartOfAccount', query).then((resp) => {
                if (resp.success) {
                    state.accounting = resp.data
                }
            })
        }

        onMounted(() => {
            filterResult()
        })

        return {
            state,
            formatQuantity,
            formatAmount,
            formatDate,
            formatDateFilter,
            filterResult,
        }
    },
}
</script>
<style>
.custom-active {
    /* color: #132c8c; */
    color: #132c8c;
    display: inline-block;
}
.active-menu {
    color: #132c8c;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px !important;
    border-bottom: 3px solid #132c8c;
    font-weight: 500;
}
</style>
